<template>
  <div>
    <ManageAcademicConferences2 />
  </div>
</template>
<script>
import ManageAcademicConferences2 from "@/components/ManageAcademicConferences/ManageAcademicConferences2";
export default {
  components: {
    ManageAcademicConferences2,
  },
  created() {},
};
</script>
