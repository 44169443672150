<template>
  <div>
    <v-dialog v-model="openDialogEdit" persistent width="700px" height="auto">
      <v-card>
        <v-card-title class="text-h5">
          งานประชุมวิชาการ - ประกาศประชุมวิชาการระดับนานาชาติ
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อประชุมวิชาการ (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อประชุมวิชาการ (TH)"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อประชุมวิชาการ (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อประชุมวิชาการ (EN)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ประจำช่วงเดือน (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.content_th"
                  placeholder="ระบุประจำช่วงเดือน (TH)"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ประจำช่วงเดือน (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.content_en"
                  placeholder="ระบุประจำช่วงเดือน (EN)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12"
                ><span class="pt-2">รูปประชุมวิชาการ</span>
                <span style="color: red">*</span>
              </v-col>
              <v-row class="ml-0">
                <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage">
                  <v-img
                    :src="showImage"
                    max-width="150px"
                    max-height="150px"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage">
                  <v-img
                    src="../../assets/noFile.png"
                    max-width="150px"
                    max-height="150px"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="9">
                  <span>{{ namePic }}</span>
                  <br />
                  <span>ขนาดรูปที่แนะนำ : 1550 x 2000px</span>
                  <br />
                  <br />
                  <br />
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="file"
                    ref="q_1_1"
                    v-on:change="submitfileMain('q_1_1')"
                  />
                </v-col>
              </v-row>

              <v-col cols="12" md="12" sm="12" class="mt-5"
                ><span class="pt-2"><b>อัพโหลดไฟล์</b></span>
                <span style="color: red">*</span>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="text-left"
                  v-for="(item, indexItemDocs) in this.documentAcademicJournals"
                  :key="indexItemDocs"
                >
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <p
                        style="color: #4caf50; cursor: pointer"
                        @click="onClickFile(item.path)"
                      >
                        {{ item.name }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="9" sm="9" class="mt-n3">
                      <v-btn
                        color="red"
                        icon
                        @click="deleteFilesDocs(indexItemDocs)"
                      >
                        <v-icon>mdi-alpha-x-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <input
                    type="file"
                    accept="application/pdf,"
                    id="file"
                    ref="attachFile1"
                    v-on:change="submitFile('attachFile1')"
                  />
                  <!-- <v-btn small @click="submitFile('attachFile1')"
                >upload</v-btn
              > -->
                </v-col>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="cancel()"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { array } from "pizzip/js/support";

export default {
  props: {
    scholarshipId: Number,
    openDialogEdit: Boolean,
    itemEdit: Object,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      namePic: "",
      cover_img_url: "",
      showImage: "",
      form: {
        name_th: null,
        name_en: null,
        content_th: null,
        content_en: null,
        publisher_th: "ประกาศประชุมวิชาการระดับนานาชาติ",
        publisher_en: null,
      },
      documentAcademicJournals: [],

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      startDateMenu: false,
      endDateMenu: false,

      dialog: false,
      checkValidate: true,
      lazy: false,

      subItems: [
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
    };
  },
  watch: {
    openDialogEdit(visible) {
      if (visible) {
        this.form = this.itemEdit;
        this.documentAcademicJournals = this.itemEdit.documentAcademicJournals;
        this.cover_img_url = this.itemEdit.cover_img_url;
        this.showImage = this.itemEdit.cover_img_url;
        this.namePic = this.itemEdit.namePic;
      }
    },
  },
  methods: {
    deleteFilesDocs(val) {
      this.documentAcademicJournals.splice(val, 1);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    onEditorChange6({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content6 = html;
    },
    onEditorChange7({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content7 = html;
    },
    onEditorChange8({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content8 = html;
    },
    onEditorChange9({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content9 = html;
    },

    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "attachFile1") {
            // this.attachFile1 = response.data.data.path;
            this.documentAcademicJournals.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }
          console.log(
            "this.documentAcademicJournals",
            this.documentAcademicJournals
          );
          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        this.namePic = this.$refs[questionNo].files[0].name;
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.cover_img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },

    async submit(pic) {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form,
          cover_img_url: this.cover_img_url,
          documentAcademicJournals: this.documentAcademicJournals,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/academicJournals/${this.itemEdit.id}`,
          // this.form,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAll");
          this.$refs.createForm.reset();
          this.showImage = "";
          this.cover_img_url = "";
          this.documentAcademicJournals = [];
          this.$refs.attachFile1.value = null;
          this.cancel();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.$emit("closeDialogEdit");
    },
  },
};
</script>